import React, { Component } from 'react'
import "../css/general-style.css"
import { AiFillPrinter } from "react-icons/ai"
import LoadingWindow from './LoadingWindow'
import pdfConfig from '../pdfConfig/pdfConfig'
import { Document, Image, View, Page, Text, pdf } from '@react-pdf/renderer'


export class DownloadOrderPDF extends Component {

    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }

    state = {
        showModal: false,
        lastRow: 0,

    }

    handleModalOpen = () => {
        this.setState({ showModal: true })
    }

    handleModalClose = () => {
        this.setState({ showModal: false })
    }

    EncabezadoInfo(Llaves, State) {

        let resultado = []
        Llaves.forEach(llave => {
            if (llave !== 'dolar') {
                if (this.props.keysDecimalFormat.includes(llave)) {
                    resultado.push(Number(State[llave]).toFixed(2))
                } else {
                    resultado.push(State[llave])
                }
            } else {
                resultado.push('$')
            }
        });
        return resultado
    }

    EncabezadoOrder(data) {
        let format = []
        let temporal = []
        let cont = 1

        for (const item of data) {
            if (cont === 4) {
                temporal.push(item)
                format.push(temporal)
                temporal = []
                cont = 1

            } else {
                cont++
                temporal.push(item)
            }
        }
        return format
    }

    calculateNewSheet() {
        let arrTam = this.props.bodyTable.length

        while (true) {
            arrTam = arrTam - 27
            if (arrTam <= 27) {
                break
            }
        }
        if (arrTam > 20) {
            return true
        }

        return false
    }

    truncDecimal(n) {
        let t = n.toString();
        let regex = /(\d*.\d{0,1})/;
        return t.match(regex)[0];
    }

    async generateDownolad() {
        await this.ModalLoading.current.showState(true);
        const blob = await pdf(
            <Document>
                <Page wrap>

                    {/*THIS IS THE HEADER OF THE DOCUMENT*/}
                    <View style={pdfConfig.HeaderStyle} fixed>
                        <View style={pdfConfig.HeaderMargin}>
                            <Image style={pdfConfig.BusinessLogo} src={this.props.companyLogo} />
                            <View style={pdfConfig.ContactInfo}>
                                {this.props.contactInfo.map((element, e) => (
                                    <Text key={e}>{element}</Text>
                                ))}
                            </View>
                        </View>
                    </View>
                    {/*ORDER INFO*/}
                    <View fixed style={pdfConfig.OrderInformation}>
                        <View style={pdfConfig.OrderTitleStyle}>
                            <Text>{this.props.OrderTitle}</Text>
                        </View>
                        {
                            this.EncabezadoOrder(this.props.OrderInfo1).map((element, e) => (
                                <View key={e} style={pdfConfig.marginInfo}>{
                                    element.map((item, i) => (
                                        (i === 0 || i === 2) ?
                                            <View style={pdfConfig.ColumnaHeader2} key={i}>
                                                <Text >{item}</Text>
                                            </View>
                                            :
                                            <View style={pdfConfig.ColumnaHeader} key={i}>
                                                <Text >{item}</Text>
                                            </View>
                                    ))}
                                </View>
                            ))
                        }
                    </View>
                    {/*TABLE INFORMATION*/}

                    <View style={pdfConfig.Table}>
                        <View fixed style={pdfConfig.CabeceraTabla}>
                            {this.props.headerTable.map((tit, e) => (
                                <Text style={pdfConfig[this.props.styleColumns[e] + "Titulo"]} key={e}>{tit}</Text>
                            ))}
                        </View>

                        {
                            this.props.bodyTable.map((elemento, e) => (

                                e % this.props.breakpoint === 0 && e !== 0 ?
                                    <View break key={e} style={pdfConfig.CuerpoTabla}>
                                        <View key={e} style={pdfConfig.FilaBreak}>{

                                            this.EncabezadoInfo(this.props.headerBodyTable, elemento).map((fila, e2) => (
                                                <View style={pdfConfig[this.props.styleColumns[e2]]} key={e2}><Text key={e2}>{fila}</Text></View>
                                            ))
                                        }</View>
                                    </View>
                                    :
                                    <View key={e} style={pdfConfig.CuerpoTabla}>
                                        <View key={e} style={pdfConfig.Fila}>{
                                            this.EncabezadoInfo(this.props.headerBodyTable, elemento).map((fila, e2) => (
                                                <View style={pdfConfig[this.props.styleColumns[e2]]} key={e2}><Text key={e2}>{fila}</Text></View>
                                            ))
                                        }</View>
                                    </View>
                            ))

                        }
                        <View style={pdfConfig.CabeceraTabla}>
                            {
                                this.props.bottomInfo.map((tit, e) => (
                                    <Text style={pdfConfig[this.props.styleColumns[e] + "Bottom"]} key={e}>{tit}</Text>
                                ))
                            }
                        </View>
                        {this.props.comment !== "" || this.props.comment !== '' ?
                            <View>
                                {
                                    this.calculateNewSheet() ?
                                        <View break style={pdfConfig.CommentStyle}>
                                            <Text>{this.props.comment}</Text>
                                        </View>
                                        :
                                        <View style={pdfConfig.CommentStyle}>
                                            <Text>{this.props.comment}</Text>
                                        </View>
                                }
                            </View> :
                            <View></View>
                        }
                    </View>
                </Page>
            </Document>
        ).toBlob()
        let URL = window.URL.createObjectURL(blob);
        window.open(URL, '_blank');
        await this.ModalLoading.current.showState(false);
    }

    render() {
        return (
            <React.Fragment>
                <LoadingWindow ref={this.ModalLoading} />
                <div className="d-grid gap-2">
                    <button hidden={this.props.hidden} id={this.props.id} disabled={this.props.disabled} type="button" onClick={() => this.generateDownolad()} className={"btn " + this.props.colorButton + " btn-lg"}><AiFillPrinter /> {this.props.titleButton}</button>
                </div>
            </React.Fragment>
        )
    }
}

