import React, { Component } from 'react'
import "../css/inventory-style.css"
import "../css/general-style.css"
import '../css/table-responsive.css'
import LoadingWindow from '../components/LoadingWindow';
import Select from 'react-select';
import Catalogue from '../components/Catalogue.js'
import { AiFillFileImage, AiOutlineCloudUpload, AiFillDollarCircle, AiFillCreditCard, AiFillFileExcel, AiOutlineReload, AiOutlineSave } from "react-icons/ai"
import LineAreaGraph from '../components/LineAreaGraph'
import { pricesFormat } from '../functions/pricesFormat';
import Swal from "sweetalert2";
import { confirmCloseAlert } from '../functions/alerts'
import { getValueCookie } from '../services/cookieService';
import { getActualDateUTC, getDateFromReports } from '../functions/dateFormat';
import { create_Delete_Update_Information, getInformationWithData, uploadFile } from '../services/CABE';
import SearchUPC from '../components/SearchUPC';
import { getIndexElement } from '../functions/searchInObject';
import ExcelDocument from '../components/ExcelDocument';
import { getDataSet } from '../functions/generateDataSetExcel';
import { getPrintHeaderCompany } from '../functions/companyInformation';
import { PriceListModal } from '../components/PriceList/PriceListModal';
import { resizeFile } from '../functions/reSizeImg';
import Catalogue2NoPrices from '../components/Catalogue2.0/Catalogue2NoPrices.js';

export default class Inventory extends Component {

    constructor(props) {
        super(props)
        this.LineAreaGraph1 = React.createRef()
        this.ModalLoading = React.createRef();
    }

    state = {
        companyPrintHeader: getPrintHeaderCompany(),
        Catalogue: [],
        Product: {
            itemCode: "",
            LastTotalUnitCost: 0,
            ShipWeight: "",
            StandardUnitCost: 0,
            UnitPrice: 0,
            abbreviatedDesc: "",
            caseupc: '',
            quantity: 0,
            upc: '',
            upc2: '',
            itemURLImage: '',
            isUpdateImg: false
        },
        FileInfo: {
            Name: '',
            File: null
        },
        Warehouse: getValueCookie('SubCompanyId') ? '003' : '000',
        PriceLevel: getValueCookie('SubCompanyId') ? '4' : undefined,
        selectOptions2: [
            { value: '003', label: 'B&B Miami Warehouse Price Level 4' },//4
            { value: '004', label: 'B&B Atlanta Warehouse Price Level 5' }//5
        ]

    }

    componentDidMount() {
        this.LineAreaGraph1.current.setTitle('Quantity sold per month year 2022')
        this.LineAreaGraph1.current.setLabels(['January', 'February', 'March', 'April', 'May', 'June'])
        this.LineAreaGraph1.current.setData(['123', '100', '12', '156', '145', '110'])

    }

    selectInputFile() {
        var button = document.getElementById('formFileInventoryInformation')
        if (button) {
            button.click()
        }
    }
    //id='UPCInventoryInfo'

    onTarget = async (e) => {
        const id = e.target.id
        switch (id) {
            case "formFileInventoryInformation":
                if (e.target.files.length > 0) {
                    const pattern = /^image/

                    if (pattern.test(e.target.files[0].type)) {
                        const temporal = this.state.FileInfo
                        temporal.Name = e.target.files[0].name
                        temporal.File = await resizeFile(e.target.files[0],375,404)         
     
                        this.setState({ FileInfo: temporal })
                    } else {
                        confirmCloseAlert('incorrect', 'The File is not a image please upload a correct File!')
                        this.setState({
                            FileInfo: {
                                Name: '',
                                File: null
                            }
                        })
                    }
                }

                break;
            default:
                break;
        }
    }

    async onKeyDown() {
        const updatedProduct = this.state.Product;
        let data = {
            ItemCode: this.state.Product.itemCode,
            idCompany: Number(getValueCookie('CompanyId')),
            upc: updatedProduct.upc,
            upc2: updatedProduct.upc2,
            caseupc: updatedProduct.caseupc
        }
        const newUpc = document.getElementById('UPCInventoryInfo').value
        const newUpc2 = document.getElementById('UPC2InventoryInfo').value 
        const newCaseupc = document.getElementById('CaseUPCInventoryInfo').value

        if (newUpc !== data.upc) {
            updatedProduct.upc = newUpc;
            data.upc = newUpc;
        }
        if (newUpc2 !== data.upc2) {
            updatedProduct.upc2 = newUpc2;
            data.upc2 = newUpc2;
        }
        if (newCaseupc !== data.caseupc) {
            updatedProduct.caseupc = newCaseupc;
            data.caseupc = newCaseupc;
        }
        
        
        await this.UpdateUpc(data);
        this.setState({ Product: updatedProduct });
    }

    async UpdateUpc(data) {
        const result = await create_Delete_Update_Information('/Items/update', data)
        if (result.status.code === 1) {
            this.updateProduct(true, data)
        } else {
            confirmCloseAlert('incorrect', 'Whoops, something is wrong. Please try again! If the result is the same call System Support')
        }
    }

    updateProduct(condition, data){
        document.getElementById('UPCInventoryInfo').value = data.upc
        document.getElementById('UPC2InventoryInfo').value = data.upc2
        document.getElementById('CaseUPCInventoryInfo').value = data.caseupc
        // this.enableUPCInput('UPCInventoryInfo', true, condition)
        // this.enableUPCInput('UPC2InventoryInfo', true, condition)
        // this.enableUPCInput('CaseUPCInventoryInfo', true, condition)
    }

    updateUPCInputs(condition) {
        document.getElementById('UPCInventoryInfo').value = this.state.Product.upc
        document.getElementById('UPC2InventoryInfo').value = this.state.Product.upc2
        document.getElementById('CaseUPCInventoryInfo').value = this.state.Product.caseupc
        this.enableUPCInput('UPCInventoryInfo', true, condition)
        this.enableUPCInput('UPC2InventoryInfo', true, condition)
        this.enableUPCInput('CaseUPCInventoryInfo', true, condition)
    }

    enableUPCInput(id, condition, firstCharge) {
        var obj = document.getElementById(id)
        if (id === 'UPCInventoryInfo' || id === 'UPC2InventoryInfo') {
            if (obj) {
                obj.disabled = condition
                if (!condition) {
                    obj.value = ''
                }

            }
        } else {
            if ((id === 'CaseUPCInventoryInfo' && getValueCookie('TypeUser') === '1') || firstCharge) {
                if (obj) {
                    obj.disabled = condition
                    if (!condition) {
                        obj.value = ''
                    }

                }
            } else {
                confirmCloseAlert('incorrect', 'Your user does not have the permissions to perform this action!')
            }
        }

    }

    onChangeSelects = async (e, id) => {

        switch (id) {
            case "Warehouse":
                this.changePriceLevel(e.value)
                this.setState({ Warehouse: e.value })
                break;
            default:
                break;
        }
        await this.getProducts()


    }

    changePriceLevel(id) {
        switch (id) {
            case "003":
                this.setState({ PriceLevel: '4' })
                break;
            case "004":
                this.setState({ PriceLevel: '5' })
                break;

            default:
                break;
        }
    }

    async updateImage() {


        let imgPreview = ''
        if (this.state.FileInfo.File !== null) {
            imgPreview = URL.createObjectURL(this.state.FileInfo.File)
        }

        Swal.fire({
            title: 'Do you want to upload this image?',
            html: 
            `<div class='ConfirmUpload'>
                <img src=${imgPreview !== '' ? imgPreview : '/assets/notavailable.png'} alt='Company' />
            </div>`,
            showCancelButton: true,
            confirmButtonText: 'Upload',
        }).then(async (result) => {

            if (result.isConfirmed) {
                this.ModalLoading.current.showState(true);
                const temporal = this.state.Product
                const data = {
                    ItemCode: temporal.itemCode,
                    idCompany: Number(getValueCookie('CompanyId')),
                    date: getActualDateUTC(),
                    createBy: getValueCookie('userName')
                }
                let formdata = new FormData()
                const blob = new Blob([JSON.stringify(data)], {
                    type: 'application/json'
                });
                await formdata.append('file', this.state.FileInfo.File, this.state.FileInfo.Name)
                await formdata.append("document", blob)
                
                const result2 = await uploadFile('/Items/updateImg', formdata, data)
                if (result2.status.code === 1) {
                    const bypassCache = new Date().getTime()
                    temporal.itemURLImage = `${result2.data[0].URL}?${bypassCache}`
                    await this.setState({
                        Product: temporal,
                        FileInfo: {
                            Name: '',
                            File: null
                        }
                    })

                    confirmCloseAlert('correct', 'The image for the Product: \n' + temporal.itemCode + '\nwas uploaded successfully')
                } else {
                    confirmCloseAlert('incorrect', 'Whoops, something is wrong. Please try again! If the result is the same call System Support')
                }


                this.ModalLoading.current.showState(false);
            }
        })
    }

    async getProducts() {
        const data = {
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
            priceLevel: this.state.PriceLevel,
            Component: null
        }
        const respuesta = await getInformationWithData('/Items/get', data);
        if (getValueCookie('CompanyId') === '6') {
            data.company = 'Mayaland Atlanta'
            data.idcompany = '3'
            const respuesta2 = await getInformationWithData('/Items/get', data);

            if (getValueCookie('CompanyId') === '6' && data.priceLevel !== '2') {
                for (const item of respuesta2.data) {

                    if (item.UnitPriceALT || item.UnitPriceALT !== 0) {
                        //let p = item.LastTotalUnitCost / (0.95)
                        //item.UnitPrice = Number(pricesFormat(p / (0.65)))
                        item.UnitPrice = Number(pricesFormat(item.UnitPriceALT))

                    } else {
                        //ELIMINAR DEL CATALOGO

                    }
                }

            }
            respuesta.status.code = respuesta2.status.code
            //respuesta.data = respuesta.data.concat(respuesta2.data)
            for (const item of respuesta2.data) {
                item.IdCompany = 3
                item.LastTotalUnitCost=Number((item.LastTotalUnitCost/0.95).toFixed(2))
                const index = getIndexElement(respuesta.data, 'itemCode', item.itemCode)
                if (index !== -1) {
                    await respuesta.data.splice(index, 1)
                }
            }
            for (const item of respuesta.data) {
                item.IdCompany = 6
                if (item.UnitPriceALT || item.UnitPriceALT !== 0) {
                    //let p = item.LastTotalUnitCost / (0.95)
                    //item.UnitPrice = Number(pricesFormat(p / (0.65)))
                    item.UnitPrice = Number(pricesFormat(item.UnitPriceALT))

                }
            }

            respuesta.data = respuesta.data.concat(respuesta2.data)
        }


        if (respuesta.status.code === 1 && respuesta.data) {
          
            this.setState({ Catalogue: respuesta.data })
        }
        await this.clickExcel()
    }
    clickExcel() {
        let button = document.getElementById('ExcelInventoryData')
        if (button) {
            button.click()
        }
    }
    async Synchronization() {
        this.ModalLoading.current.showState(true);
        const info = {
            IdCompany: Number(getValueCookie('CompanyId'))
        }

        const data = await getInformationWithData('/Items/sync', info)



        if (data.status.code === 1) {
            confirmCloseAlert('correct', `The products has been Synchronized.\nQuantity of Synchronized products: ${data.data.sincronizados}\nQuantity of items created: ${data.data.Creados}`)

        } else {
            //ERROR EN LA SINCRONIZACIÓN
            confirmCloseAlert('incorrect', `Whoops We have a error. Please try again!`)

        }
        this.ModalLoading.current.showState(false);
    }


    render() {
        return (
            <div className='InventoryInfo lobbyContainer'>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Product Information</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please select or search a product.</p>
                </div>
                <div class="row justify-content-center">
                    {/* Item image */}
                    <div hidden={!this.state.Product.itemCode} className='col-sm-12 col-md-6 col-lg-4 order-1 mb-3'>
                        <div class="card inventory-card">
                            <div class="card-body text-center">
                                <div className='mb-3'>
                                    <div className='InventoryIMG pb-5 mb-3'>
                                        <img key={this.state.Product.itemURLImage} id='PrincipalImgInventory' src={this.state.Product.itemURLImage ? this.state.Product.itemURLImage : '/assets/notavailable.png'} alt='Company' />
                                    </div>
                                    <div hidden={!this.state.Product.itemCode}>
                                        <button onClick={() => this.selectInputFile()} type="button" className="btn  btn-lg w-50 aquaButton"><AiFillFileImage /> Upload New Image</button>
                                        <input accept='image/*' hidden type="file" id="formFileInventoryInformation" onChange={this.onTarget} />
                                        <div hidden={this.state.FileInfo.Name === ''} className='row desciptioninfo'>
                                            <div className='col-sm-2'></div>
                                            <div className='col-sm-8 text-center'>
                                                <p className="lead">Name of File to Upload: {this.state.FileInfo.Name}</p>
                                            </div>
                                            <div className='col-sm-2'></div>
                                            <div className='col-sm-12'>
                                                <button onClick={() => this.updateImage()} type="button" className="btn  btn-lg w-50 orangeButton"><AiOutlineCloudUpload /> {this.state.Product.itemURLImage ? "Update" : "Upload"} Image</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Item information */}
                    <div hidden={!this.state.Product.itemCode} className="col-sm-12 col-md-12 col-lg-4 order-3 order-md-3 order-lg-2">
                        <div class="card inventory-card">
                            <div class="card-body">
                                <div className='mb-3'>
                                    {/* General information */}
                                    <div className='col-sm-12'>
                                        <div className='row productInfoText'>
                                            <div className='row'>
                                                <h3>
                                                    <span className='badge bg-primary me-3'>{this.state.Product.itemCode}</span>
                                                    <span className='badge bg-secondary'>On hand: {this.state.Product.quantity}</span>
                                                </h3>
                                            </div>
                                            <h2 className="h2">{(this.state.Product.completeDesc !== "null" && this.state.Product.completeDesc) ? this.state.Product.completeDesc : this.state.Product.abbreviatedDesc}</h2>
                                        </div>
                                        {getValueCookie('userName') === 'sergio.lopez' ?
                                        <div>
                                            <div className='col-sm-12 input-group'>
                                                <span className='input-group-text product-input-text'>UPC:</span>
                                                {/* <div className="input-group input-group-lg pb-4" onDoubleClick={() => this.enableUPCInput("UPCInventoryInfo", false, false)} > */}
                                                    <input type="text" id='UPCInventoryInfo' defaultValue={this.state.Product.upc} className="form-control" />
                                                {/* </div> */}
                                            </div>
                                            <div className='row productInfoText'>
                                                <div className='col-sm-12 input-group'>
                                                    <span className='input-group-text product-input-text'>UPC2:</span>
                                                    {/* <div className="input-group input-group-lg pb-4" onDoubleClick={() => this.enableUPCInput("UPC2InventoryInfo", false, false)}> */}
                                                        <input type="text" id='UPC2InventoryInfo' defaultValue={this.state.Product.upc2} className="form-control " />
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <div className='row productInfoText'>
                                                <div className='col-sm-12 input-group'>
                                                    <span className='input-group-text product-input-text'>Case UPC:</span>
                                                    {/* <div className="input-group input-group-lg pb-4" onDoubleClick={() => this.enableUPCInput("CaseUPCInventoryInfo", false, false)} > */}
                                                        <input type="text" id='CaseUPCInventoryInfo' defaultValue={this.state.Product.caseupc} className="form-control" />
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <div className='row productInfoText'>
                                                <div className='col-sm-12 input-group'>
                                                    <span className='input-group-text product-input-text'>U/M:</span>
                                                    {/* <div className="input-group input-group-lg pb-4"> */}
                                                        <input type="text" id="ItemUM" className="form-control" />
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <button className="btn greenButton btn-lg w-100 h-100" type="button" onClick={() => this.onKeyDown()}> <AiOutlineSave /> Save Product</button>
                                        </div>
                                        :
                                        <div>
                                            <div className='col-sm-12 input-group'>
                                                <span className='input-group-text product-input-text'>UPC:</span>
                                                {/* <div className="input-group input-group-lg pb-4" onDoubleClick={() => this.enableUPCInput("UPCInventoryInfo", false, false)} > */}
                                                    <input disabled type="text" id='UPCInventoryInfo' defaultValue={this.state.Product.upc} className="form-control" onKeyDown={this.onKeyDown} />
                                                {/* </div> */}
                                            </div>
                                            <div className='row productInfoText'>
                                                <div className='col-sm-12 input-group'>
                                                    <span className='input-group-text product-input-text'>UPC2:</span>
                                                    {/* <div className="input-group input-group-lg pb-4" onDoubleClick={() => this.enableUPCInput("UPC2InventoryInfo", false, false)}> */}
                                                        <input disabled type="text" id='UPC2InventoryInfo' defaultValue={this.state.Product.upc2} className="form-control " onKeyDown={this.onKeyDown} />
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <div className='row productInfoText'>
                                                <div className='col-sm-12 input-group'>
                                                    <span disabled className='input-group-text product-input-text'>Case UPC:</span>
                                                    {/* <div className="input-group input-group-lg pb-4" onDoubleClick={() => this.enableUPCInput("CaseUPCInventoryInfo", false, false)} > */}
                                                        <input disabled type="text" id='CaseUPCInventoryInfo' defaultValue={this.state.Product.caseupc} className="form-control" onKeyDown={this.onKeyDown} />
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <div className='row productInfoText'>
                                                <div className='col-sm-12 input-group'>
                                                    <span className='input-group-text product-input-text'>U/M:</span>
                                                    {/* <div className="input-group input-group-lg pb-4"> */}
                                                        <input type="text" id="ItemUM" className="form-control" />
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    {/* Physical details */}
                                    <div className='col-sm-12'>
                                        <h3 className='h3 text-start py-4'>Physical Details</h3>
                                        <div className='row productInfoText'>
                                            <div className='col-sm-12 input-group'>
                                                <span className='input-group-text product-input-text'>Weight:</span>
                                                {/* <div className="input-group input-group-lg pb-4"> */}
                                                    <input type="number" id='Itemweight' className="form-control" />
                                                    <span className='input-group-text' id='Itemweight'>lbs</span>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        <div className='row productInfoText'>
                                            <div className='col-sm-12 input-group'>
                                                <span className='input-group-text product-input-text'>Length:</span>
                                                {/* <div className="input-group input-group-lg pb-4"> */}
                                                    <input type="number" id='ItemLength' className="form-control" />
                                                    <span className='input-group-text' id='ItemLength'>in</span>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        <div className='row productInfoText'>
                                            <div className='col-sm-12 input-group'>
                                                <span className='input-group-text product-input-text'>Height:</span>
                                                {/* <div className="input-group input-group-lg pb-4"> */}
                                                    <input type="number" id='ItemHeight' className="form-control" />
                                                    <span className='input-group-text' id='ItemHeight'>in</span>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        <div className='row productInfoText'>
                                            <div className='col-sm-12 input-group'>
                                                <span className='input-group-text product-input-text'>Width:</span>
                                                {/* <div className="input-group input-group-lg pb-4"> */}
                                                    <input type="number" id='ItemWidth' className="form-control" />
                                                    <span className='input-group-text' id='ItemWidth'>in</span>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        <div hidden className='row'>
                                            <div className='col-sm-12'>
                                                <p className='text-start display-5 pb-2' >Financial information: <AiFillCreditCard /></p>
                                                <div className='row productInfoText'>
                                                    <div className='col-sm-12'>
                                                        <p><AiFillDollarCircle /> Unit Price:</p>
                                                    </div>
                                                    <div className='col-sm-12'>
                                                        <div className="input-group input-group-lg pb-4">
                                                            <input id='ItemUnitPriceInventory' disabled value={pricesFormat(this.state.Product.UnitPrice)} type="text" className="form-control text-end" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row productInfoText'>
                                                    <div className='col-sm-12'>
                                                        <p><AiFillDollarCircle /> Unit Cost:</p>
                                                    </div>
                                                    <div className='col-sm-12'>
                                                        <div className="input-group input-group-lg pb-4">
                                                            <input id='ItemUnitCostInventory' type="text" disabled value={pricesFormat(this.state.Product.StandardUnitCost)} className="form-control text-end" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div hidden className='row productInfoText'>
                                                    <div className='col-sm-12'>
                                                        <LineAreaGraph ref={this.LineAreaGraph1} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Cataloge and action. buttons */}
                    <div className="col-sm-12 col-md-6 col-lg-4 order-2 order-md-2 order-lg-3 d-md-block mb-3">
                        <div className='col-sm-12 mb-3'>
                            <div class="card inventory-card">
                                <div class="card-body text-center">
                                    <h5 class="card-title pb-4">Search and Catalogue</h5>
                                    <div className='mb-3'>
                                        <div className="input-group input-group-lg">
                                            <SearchUPC ComponentName={'Inventory'} disabled={false} priceLevel={this.state.PriceLevel} Padre={this} />
                                        </div>
                                    </div>
                                    <Catalogue2NoPrices PriceLevel={this.state.PriceLevel} nombrePadre={'ProductInformation'} Array={'Catalogue'} Padre={this} titleButton='Open Catalogue' needPrice={getValueCookie('SalesPerson') === '0010' ? false : true} needCost={getValueCookie('SalesPerson') === '0010' ? true : false} productList={'ProductList'}/>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div class="card inventory-card">
                                <div class="card-body text-center">
                                    <h5 class="card-title pb-4">Action Panel</h5>
                                    <div className='d-grid gap-2 col-sm-12 action-panel'>
                                        {/* Action buttons */}
                                        <ExcelDocument hidden={true} id={"ExcelInventoryData"} data={
                                            getValueCookie('TypeUser') === '1' ?
                                                getDataSet(this.state.Catalogue, ['Item Code', 'Product Line', 'Description', 'On Hand', 'Last Total Unit Cost', 'Unit Price','UPC','UPC 2','CASE UPC'], ['itemCode', 'ProductLineDesc', 'abbreviatedDesc', 'quantity', 'LastTotalUnitCost', 'UnitPrice','upc','upc2','caseupc'])
                                                :
                                                getDataSet(this.state.Catalogue, ['Item Code', 'Product Line', 'Description', 'On Hand'], ['itemCode', 'ProductLineDesc', 'abbreviatedDesc', 'quantity'])
                                        } sheetname={"Inventory"} archname={"INVENTORY COMPANY " + getValueCookie('Company') + " DATE " + getDateFromReports()} ></ExcelDocument>
                                        {
                                            getValueCookie('CompanyId') === '3' ?
                                                <div className='col-sm-12'>
                                                    <PriceListModal id={"PriceLevelList"} colorButton="purpleButton" title="Generate Price Level List"
                                                        hidden={false}
                                                        disabled={false}
                                                        titleButton='Generate Price Level List'
                                                    />
                                                </div>
                                            : <div className='col-sm-5' />
                                        }
                                        <div className='mt-2'>
                                            <button className="btn greenButton btn-lg w-100 h-100" type="button" onClick={() => this.getProducts()}> <AiFillFileExcel /> Download Report</button>
                                        </div>
                                        <div className='mt-2'>
                                            <button className="btn orangeButton btn-lg w-100 h-100" type="button" onClick={() => this.Synchronization()} ><AiOutlineReload /> Product Sync</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
