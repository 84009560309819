import React, { Component } from 'react'
import { BsArrowRepeat } from "react-icons/bs";
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import "../css/general-style.css"
import '../css/table-responsive.css'
import "../css/inventory-style.css"
import { getValueCookie } from '../services/cookieService';
import { getInformationWithData } from '../services/CABE';
import { DeleteCharactersNotAccepted, ItemDesc, MonthInLetters, NumberFormat, OrderArray } from '../functions/generalFunctions';
import { getIndexElement } from '../functions/searchInObject'
import { pricesFormat } from '../functions/pricesFormat';
import { v4 as uuidv4 } from 'uuid';
import LoadingWindow from '../components/LoadingWindow';
import ExcelDocument from '../components/ExcelDocument';
import { getDataSet, getDataSetShorts } from '../functions/generateDataSetExcel';
import ModalOrders from '../components/ModalComponent';
import { DateFormatMAS90, getDateFromReports, OrderArrayByDateGenericLines } from '../functions/dateFormat';
import { confirmCloseAlert } from '../functions/alerts';
export default class Shorts extends Component {
    constructor(props) {
        super(props)
        this.BarGraph1 = React.createRef()
        this.ModalLoading = React.createRef();

    }



    state = {
        HeaderTable: {
            Row1: [],
            Row2: [],
            Row3: []
        },
        Body: [],
        BodyFilter: [],
        AllInformation: [],
        Totals: [],
        showModal1: false,
        currentItem: {
            ItemCode: '',
            ItemCodeDesc: '',
            ProductLine: '',
            itemURL: '',
            totalShorts: 0,
            totalQuantityShipped: 0,
            totalQuantityOrdered: 0,
            totalShort: 0
        },
        AllNoProccessInformation: [],
        ShortDetail: [],
        ShortDetailWithTotal: [],
        CheckFilters: [],
        flag: true

    }

    componentDidMount() {

    }

    TableHeader(splitStartDate, splitEndDate) {
        let years = [{ year: '', span: 4 }]
        let months = []
        let General = [{ year: '', month: '', title: ['   #   ', 'Item Code', 'Product Line', 'Description'] }]

        for (let a = Number(splitStartDate[0]); a <= Number(splitEndDate[0]); a++) {
            if (a === Number(splitStartDate[0]) && a === Number(splitEndDate[0])) {
                var cont1 = 0
                for (let b = Number(splitStartDate[1]); b <= Number(splitEndDate[1]); b++) {
                    cont1++
                    months.push({ year: String(a), num: b, month: MonthInLetters(b) })
                    General = General.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Short', '', 'Short ($)'] }])
                }
                const temp = {
                    year: String(a),
                    span: cont1 * 3
                }
                years.push(temp)
                break
            } else if (a === Number(splitStartDate[0])) {
                var cont2 = 0
                for (let b = Number(splitStartDate[1]); b <= 12; b++) {
                    cont2++
                    months.push({ year: String(a), num: b, month: MonthInLetters(b) })
                    General = General.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Short', '', 'Short ($)'] }])
                }

                const temp = {
                    year: String(a),
                    span: cont2 * 3
                }
                years.push(temp)

            } else if (a === Number(splitEndDate[0])) {
                var cont3 = 0
                for (let b = 1; b <= Number(splitEndDate[1]); b++) {
                    cont3++
                    months.push({ year: String(a), num: b, month: MonthInLetters(b) })
                    General = General.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Short', '', 'Short ($)'] }])
                }

                const temp = {
                    year: String(a),
                    span: cont3 * 3
                }
                years.push(temp)

            } else {

                for (let b = 1; b <= 12; b++) {

                    months.push({ year: String(a), num: b, month: MonthInLetters(b) })
                    General = General.concat([{ year: String(a), month: b < 10 ? '0' + String(b) : String(b), title: ['Short', '', 'Short ($)'] }])
                }

                const temp = {
                    year: String(a),
                    span: 12 * 3
                }
                years.push(temp)
            }

        }

        this.setState({
            HeaderTable: {
                Row1: years,
                Row2: months,
                Row3: General
            }
        })

        return
    }
    clearAll() {
        this.setState({
            HeaderTable: {
                Row1: [],
                Row2: [],
                Row3: []
            },
            Body: [],
            BodyFilter: [],
            AllInformation: [],
            Totals: [],
            currentItem: {
                ItemCode: '',
                ItemCodeDesc: '',
                ProductLine: '',
                itemURL: ''
            },
            AllNoProccessInformation: [],
            ShortDetail: [],
        })
    }

    async getShorts() {
        this.ModalLoading.current.showState(true);
        this.clearAll()
        var date1 = document.getElementById('startShortFilterDate').value
        var date2 = document.getElementById('endShortFilterDate').value
        const splitStartDate = date1.split('-')
        const splitEndDate = date2.split('-')
        if (splitStartDate.length === 3 && splitEndDate.length === 3) {
            this.TableHeader(splitStartDate, splitEndDate)
            const data = {
                Start: date1,
                End: date2,
                idCompany: Number(getValueCookie('CompanyId'))
            }
            const result = await getInformationWithData('/invoice/report/shorts', data)

            let temporal = []
            let filters = []
            if (result.status.code === 1) {
               


                
                for (const item of result.data) {
                    let newMonth=item.InvoiceDate ? item.InvoiceDate.split('-') : []
                    if(newMonth.length===3){
                        item.Month=MonthInLetters(newMonth[1])
                    }else{
                        item.Month='' 
                    }
                   
                    const index = getIndexElement(temporal, 'ItemCode', item.ItemCode)
                    const indexFilter = getIndexElement(filters, 'Route', item.UDF_RUTA)


                    if (indexFilter === -1) {
                        filters.push({ Route: item.UDF_RUTA, check: true })
                    }



                    const splitDate = item.InvoiceDate ? item.InvoiceDate.split('-') : []

                    if (index === -1 && splitDate.length === 3) {

                        const json = {
                            ItemCode: item.ItemCode,
                            ItemCodeDesc: item.ItemCodeDesc,
                            ProductLine: item.ProductLineDesc,
                            Detail: []
                        }

                        const year = {
                            year: String(splitDate[0]),
                            Months: []
                        }

                        const month = {
                            month: String(splitDate[1]),
                            dolar: '$',
                            shorts: item.shorts,
                            SalesPrice: Number(item.UnitPrice) * Number(item.shorts),
                            PurchaseCost: item.UnitCost,
                        }
                        year.Months.push(month)
                        json.Detail.push(year)
                        temporal.push(json)
                    } else if (splitDate.length === 3) {
                        const element = temporal[index]
                        const indexYear = getIndexElement(element.Detail, 'year', String(splitDate[0]))
                        if (indexYear === -1) {
                            const year2 = {
                                year: String(splitDate[0]),
                                Months: []
                            }

                            const month2 = {
                                month: String(splitDate[1]),
                                dolar: '$',
                                shorts: item.shorts,
                                SalesPrice: Number(item.UnitPrice) * Number(item.shorts),
                                PurchaseCost: item.UnitCost,
                            }
                            year2.Months.push(month2)
                            element.Detail.push(year2)
                        } else {
                            const element2 = element.Detail[indexYear]
                            const indexMonth = getIndexElement(element2.Months, 'month', String(splitDate[1]))
                            if (indexMonth === -1) {
                                const month3 = {
                                    month: String(splitDate[1]),
                                    dolar: '$',
                                    shorts: item.shorts,
                                    SalesPrice: Number(item.UnitPrice) * Number(item.shorts),
                                    PurchaseCost: item.UnitCost,
                                }
                                element2.Months.push(month3)
                                element.Detail[indexYear] = element2
                                temporal[index] = element

                            } else {
                                //AÑADIR PROMEDIOS Y SUMAS 
                                const element3 = element2.Months[indexMonth]
                                element3.shorts += item.shorts
                                element3.SalesPrice += (Number(item.UnitPrice) * Number(item.shorts))
                                element3.PurchaseCost += item.UnitCost
                                element2.Months[indexMonth] = element3
                                element.Detail[indexYear] = element2
                                temporal[index] = element
                            }
                        }

                    }
                }
                this.setState({ AllInformation: result.data })
                for (const month of this.state.HeaderTable.Row2) {
                    for (const item of temporal) {
                        const indexyear = getIndexElement(item.Detail, 'year', month.year)
                        if (indexyear === -1) {
                            const add = {
                                year: month.year,
                                Months: [{
                                    PurchaseCost: '-',
                                    SalesPrice: '-',
                                    dolar: "$",
                                    month: String(month.num).length === 1 ? "0" + String(month.num) : String(month.num),
                                    shorts: '-',
                                }]
                            }
                            item.Detail.push(add)
                        } else {
                            const temporal = item.Detail[indexyear]
                            const indexmonth = getIndexElement(temporal.Months, 'month', String(month.num).length === 1 ? "0" + String(month.num) : String(month.num))
                            if (indexmonth === -1) {
                                const add2 = {
                                    PurchaseCost: '-',
                                    SalesPrice: '-',
                                    dolar: "$",
                                    month: String(month.num).length === 1 ? "0" + String(month.num) : String(month.num),
                                    shorts: '-',
                                }
                                temporal.Months.push(add2)
                                item.Detail[indexyear] = temporal
                            }
                        }
                    }

                }



                temporal = await ItemDesc(temporal, 'ItemCodeDesc', 'ItemCode')

                temporal = this.Order(temporal, 'ItemCode', true)


                for (const item of temporal) {
                    for (const detail of item.Detail) {
                        detail.Months = this.Order(detail.Months, 'month', true)
                    }
                    item.Detail = this.Order(item.Detail, 'year', true)
                }


                await this.setState({ Body: temporal, BodyFilter: temporal, Totals: this.getTotals(temporal), AllNoProccessInformation: result.data, CheckFilters: filters })
            }
        } else {
            confirmCloseAlert('incorrect', 'Both dates must be established\n to make the report!')
        }
        this.ModalLoading.current.showState(false);
    }

    contains(parametro, busqueda) {
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
    }

    searchShorts = e => {

        let search = e.target.value;

        let orders = this.state.Body.filter((item) => {
            if (((
                this.contains(item.ItemCode, search) ||
                this.contains(item.ProductLine, search) ||
                this.contains(item.ItemCodeDesc, search)

            ))
            ) {
                return item
            } else {
                return null
            }
        });
        this.setState({ BodyFilter: orders, Totals: this.getTotals(orders) });

    }

    getTotals(temporal) {
        let totals = []
        let flag = true
        let count = 0
        for (const item of temporal) {
            for (const detail of item.Detail) {
                for (let a = 0; a < detail.Months.length; a++) {
                    if (flag) {
                        totals.push(detail.Months[a].shorts === '-' ? 0 : Number(detail.Months[a].shorts))
                        totals.push('$')
                        totals.push(detail.Months[a].SalesPrice === '-' ? 0 : Number(detail.Months[a].SalesPrice))
                        //totals.push('$')
                        //totals.push(detail.Months[a].PurchaseCost === '-' ? 0 : Number(detail.Months[a].PurchaseCost))
                    } else {
                        totals[count] += detail.Months[a].shorts === '-' ? 0 : Number(detail.Months[a].shorts)
                        count += 2
                        totals[count] += detail.Months[a].SalesPrice === '-' ? 0 : Number(detail.Months[a].SalesPrice)
                        //count += 2
                        //totals[count] += detail.Months[a].PurchaseCost === '-' ? 0 : Number(detail.Months[a].PurchaseCost)
                        count += 1
                    }

                }
            }
            flag = false
            count = 0
        }
        for (let num = 0; num < totals.length; num++) {
            if (totals[num] !== '$') {
                totals[num] = NumberFormat(pricesFormat(totals[num]))
            }
        }

        return totals
    }

    Order(arreglo, llave, bandera) {

        const l = arreglo.length;
        if (bandera) {
            for (let i = 0; i < l; i++) {
                for (let j = 0; j < l - 1 - i; j++) {
                    if (arreglo[j][llave] > arreglo[j + 1][llave]) {
                        [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                    }
                }
            }
        } else {
            for (let i = 0; i < l; i++) {
                for (let j = 0; j < l - 1 - i; j++) {
                    if (arreglo[j][llave] < arreglo[j + 1][llave]) {
                        [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                    }
                }
            }
        }
        return arreglo;
    }

    handleModalOpen = (modal) => {
        var temporal = this.state[modal]
        temporal = true
        this.setState({ [modal]: temporal })
    }

    handleModalClose = (modal) => {
        var temporal = this.state[modal]
        temporal = false
        this.setState({ [modal]: temporal })
    }
    clearOnlyShortDetail() {
        this.setState({
            currentItem: {
                ItemCode: '',
                ItemCodeDesc: '',
                ProductLine: '',
                itemURL: '',
                totalShorts: 0,
                totalQuantityShipped: 0,
                totalQuantityOrdered: 0,
                totalShort: 0
            },
            ShortDetail: [],
        })
    }

    async DetailofShort(itemCode) {
        this.clearOnlyShortDetail()
        const temporal = this.state.currentItem
        temporal.ItemCode = itemCode.ItemCode
        temporal.ItemCodeDesc = itemCode.ItemCodeDesc
        temporal.ProductLine = itemCode.ProductLine

        let SpecificDetail = []
        var total = 0
        var totalShipped = 0
        var totalOrdered = 0
        var totalShorts = 0
        for (const item of this.state.AllNoProccessInformation) {
            const index = getIndexElement(this.state.CheckFilters, 'Route', item.UDF_RUTA)
            if (index !== -1) {
                if (item.ItemCode === itemCode.ItemCode && this.state.CheckFilters[index].check) {
                    total += item.UnitPrice * item.shorts
                    totalShipped += item.QuantityShipped
                    totalOrdered += item.QuantityOrdered
                    totalShorts += item.shorts
                    item.TotalShort = item.UnitPrice * item.shorts
                    item.UnitCostD = '$' + pricesFormat(item.UnitCost)
                    item.UnitPriceD = '$' + pricesFormat(item.UnitPrice)
                    item.TotalShortD = '$' + pricesFormat(item.UnitPrice * item.shorts)
                    SpecificDetail.push(item)
                }
            }


        }
        temporal.totalShorts = total
        temporal.totalQuantityShipped = totalShipped
        temporal.totalQuantityOrdered = totalOrdered
        temporal.totalShort = totalShorts
        SpecificDetail.push()

        SpecificDetail = OrderArrayByDateGenericLines(SpecificDetail, 'InvoiceDate')
        const data = {
            idCompany: Number(getValueCookie('CompanyId')),
            ItemCode: itemCode.ItemCode
        }
        const resultImg = await getInformationWithData('/Items/get/Img', data)

        if (resultImg.status.code === 1) {
            if (resultImg.data.itemURLImage) {
                if (resultImg.data.itemURLImage !== '') {
                    const date = new Date().getTime()
                    temporal.itemURL = `${resultImg.data.itemURLImage}?${date}`
                } else {
                    temporal.itemURL = ''
                }
            } else {
                temporal.itemURL = ''
            }
        }

        await this.setState({ ShortDetail: SpecificDetail, currentItem: temporal })
        var totalRow = {
            InvoiceDate: 'TOTAL',
            QuantityShipped: totalShipped,
            QuantityOrdered: totalOrdered,
            shorts: totalShorts,
            TotalShortD: '$' + pricesFormat(total)
        }
        var copySpecificDetail = JSON.parse(JSON.stringify(SpecificDetail))
        copySpecificDetail.push(totalRow)
        await this.setState({ ShortDetailWithTotal: copySpecificDetail })
        await this.handleModalOpen('showModal1')
    }

    async OrderPriceMargins(option, pos) {
        var temporal = this.state.BodyFilter
        switch (option) {
            case "Item Code":
                temporal = OrderArray(temporal, "ItemCode", this.state.flag)
                this.setState({ BodyFilter: temporal, flag: !this.state.flag })
                break;
            case "Product Line":
                temporal = OrderArray(temporal, "ProductLine", this.state.flag)
                this.setState({ BodyFilter: temporal, flag: !this.state.flag })
                break;
            case "Short":
                let t1 = await this.orderShorts(temporal, pos, "shorts")
                await this.setState({ BodyFilter: t1, flag: !this.state.flag })
                break;
            case "Short ($)":
                let t2 = await this.orderShorts(temporal, pos, "SalesPrice")
                await this.setState({ BodyFilter: t2, flag: !this.state.flag })
                break;
            default:
                break;
        }
    }

    async orderShorts(array, conditions, key) {

        const l = array.length

        for (let i = 0; i < l; i++) {
            for (let j = 0; j < l - 1 - i; j++) {

                const pos1 = await getIndexElement(array[j].Detail, 'year', conditions.year)
                const pos2 = await getIndexElement(array[j].Detail[pos1].Months, 'month', conditions.month)
                const pos3 = await getIndexElement(array[j + 1].Detail, 'year', conditions.year)
                const pos4 = await getIndexElement(array[j + 1].Detail[pos3].Months, 'month', conditions.month)
                const m = array[j].Detail[pos1].Months[pos2][key] === '-' ? 0 : Number(array[j].Detail[pos1].Months[pos2][key])
                const n = array[j + 1].Detail[pos3].Months[pos4][key] === '-' ? 0 : Number(array[j + 1].Detail[pos3].Months[pos4][key])
                if (this.state.flag) {
                    if (m > n) {
                        [array[j], array[j + 1]] = await [array[j + 1], array[j]];
                    }
                } else {
                    if (m < n) {
                        [array[j], array[j + 1]] = await [array[j + 1], array[j]];
                    }
                }

            }
        }


        return array
    }

    checkSpecific = async (e, actualStat, Route) => {
        await this.ChangeCheck(actualStat, Route)
        await this.UpdateShortsByFilter()
    }

    ChangeCheck(stat, route) {
        const temporal = this.state.CheckFilters
        const index = getIndexElement(temporal, 'Route', route)

        if (index !== -1) {
            temporal[index].check = !stat
        }
        this.setState({ CheckFilters: temporal })

    }


    async UpdateShortsByFilter() {
        this.ModalLoading.current.showState(true);
        let temporal = []
        for (const item of this.state.AllNoProccessInformation) {

            const index = getIndexElement(temporal, 'ItemCode', item.ItemCode)
            const indexFilter = getIndexElement(this.state.CheckFilters, 'Route', item.UDF_RUTA)

            if (indexFilter !== -1) {
                if (this.state.CheckFilters[indexFilter].check) {
                    const splitDate = item.InvoiceDate ? item.InvoiceDate.split('-') : []

                    if (index === -1 && splitDate.length === 3) {

                        const json = {
                            ItemCode: item.ItemCode,
                            ItemCodeDesc: item.ItemCodeDesc,
                            ProductLine: item.ProductLineDesc,
                            Detail: []
                        }

                        const year = {
                            year: String(splitDate[0]),
                            Months: []
                        }

                        const month = {
                            month: String(splitDate[1]),
                            dolar: '$',
                            shorts: item.shorts,
                            SalesPrice: Number(item.UnitPrice) * Number(item.shorts),
                            PurchaseCost: item.UnitCost,
                        }
                        year.Months.push(month)
                        json.Detail.push(year)
                        temporal.push(json)
                    } else if (splitDate.length === 3) {
                        const element = temporal[index]
                        const indexYear = getIndexElement(element.Detail, 'year', String(splitDate[0]))
                        if (indexYear === -1) {
                            const year2 = {
                                year: String(splitDate[0]),
                                Months: []
                            }

                            const month2 = {
                                month: String(splitDate[1]),
                                dolar: '$',
                                shorts: item.shorts,
                                SalesPrice: Number(item.UnitPrice) * Number(item.shorts),
                                PurchaseCost: item.UnitCost,
                            }
                            year2.Months.push(month2)
                            element.Detail.push(year2)
                        } else {
                            const element2 = element.Detail[indexYear]
                            const indexMonth = getIndexElement(element2.Months, 'month', String(splitDate[1]))
                            if (indexMonth === -1) {
                                const month3 = {
                                    month: String(splitDate[1]),
                                    dolar: '$',
                                    shorts: item.shorts,
                                    SalesPrice: Number(item.UnitPrice) * Number(item.shorts),
                                    PurchaseCost: item.UnitCost,
                                }
                                element2.Months.push(month3)
                                element.Detail[indexYear] = element2
                                temporal[index] = element

                            } else {
                                //AÑADIR PROMEDIOS Y SUMAS 
                                const element3 = element2.Months[indexMonth]
                                element3.shorts += item.shorts
                                element3.SalesPrice += (Number(item.UnitPrice) * Number(item.shorts))
                                element3.PurchaseCost += item.UnitCost
                                element2.Months[indexMonth] = element3
                                element.Detail[indexYear] = element2
                                temporal[index] = element
                            }
                        }

                    }
                }
            }


        }

        for (const month of this.state.HeaderTable.Row2) {
            for (const item of temporal) {
                const indexyear = getIndexElement(item.Detail, 'year', month.year)
                if (indexyear === -1) {
                    const add = {
                        year: month.year,
                        Months: [{
                            PurchaseCost: '-',
                            SalesPrice: '-',
                            dolar: "$",
                            month: String(month.num).length === 1 ? "0" + String(month.num) : String(month.num),
                            shorts: '-',
                        }]
                    }
                    item.Detail.push(add)
                } else {
                    const temporal = item.Detail[indexyear]
                    const indexmonth = getIndexElement(temporal.Months, 'month', String(month.num).length === 1 ? "0" + String(month.num) : String(month.num))
                    if (indexmonth === -1) {
                        const add2 = {
                            PurchaseCost: '-',
                            SalesPrice: '-',
                            dolar: "$",
                            month: String(month.num).length === 1 ? "0" + String(month.num) : String(month.num),
                            shorts: '-',
                        }
                        temporal.Months.push(add2)
                        item.Detail[indexyear] = temporal
                    }
                }
            }

        }



        temporal = await ItemDesc(temporal, 'ItemCodeDesc', 'ItemCode')

        temporal = this.Order(temporal, 'ItemCode', true)


        for (const item of temporal) {
            for (const detail of item.Detail) {
                detail.Months = this.Order(detail.Months, 'month', true)
            }
            item.Detail = this.Order(item.Detail, 'year', true)
        }


        await this.setState({ Body: temporal, BodyFilter: temporal, Totals: this.getTotals(temporal) })

        this.ModalLoading.current.showState(false);
    }


    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Report of Shorts</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please select an start and end date</p>
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col-sm-10'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-4'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                    <label className='form-label' for="startShortFilterDate">Start</label>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='startShortFilterDate' type="date" />
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <label className='form-label' for="endShortFilterDate">End</label>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='endShortFilterDate' type="date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4 InputTextBolder text-center d-flex align-items-center'>
                                <button onClick={() => this.getShorts()} type="button" className="btn blueButton btn-lg w-100"><BsArrowRepeat /> Load</button>
                            </div>
                        </div>
                        <div>
                            <div className="col-sm-12 col-md-4 mx-auto mb-5">
                                <ExcelDocument
                                    hidden={this.state.Body.length === 0}
                                    archname={'Shorts ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                    sheetname2={['Shorts Detail ' + getValueCookie('Company')]}
                                    sheetname={'Shorts ' + getValueCookie('Company')}
                                    data={getDataSetShorts(this.state.HeaderTable.Row1, this.state.HeaderTable.Row2, this.state.HeaderTable.Row3, this.state.Body, this.state.Totals)}
                                    data2={[getDataSet(this.state.AllInformation, ['Item Code', 'Product Line', 'Description', 'Invoice No', 'Customer No', 'Customer Name', 'Price Level', 'Sales Person', 'Route', 'Date','Month', 'Quantity Ordered', 'Quantity Shipped', 'Shorts', 'Unit Cost ($)', 'Unit Price ($)'], ['ItemCode', 'ProductLineDesc', 'ItemCodeDesc', 'InvoiceNo', 'CustomerNo', 'BillToName', 'PriceLevel', 'SalespersonNo', 'UDF_RUTA', 'InvoiceDate','Month', 'QuantityOrdered', 'QuantityShipped', 'shorts', 'UnitCost', 'UnitPrice'])]}
                                />
                            </div>
                        </div>
                        <div className='row pb-2'>
                            <div className='col-12'>
                                <div className="input-group input-group-lg pb-4" >
                                    <input type="text" autoComplete='off' className="form-control" id='searchShortItem' placeholder='Search Item by ItemCode, Line Product, Description...' onChange={this.searchShorts}  hidden={this.state.Body.length === 0}/>
                                </div>
                            </div>
                        </div>
                        <div className='row pb-2'>
                            <p className='lead text-muted inventory-subtitle text-center pb-4' hidden={this.state.Body.length === 0}>Please click on these tags to show or hide them.</p>
                            <div className='d-flex justify-content-between flex-wrap gap-4 mb-4'>
                                {
                                    this.state.CheckFilters.map((element, e) => (
                                        <div className='form-check' key={e}>
                                            <input onChange={(e) => this.checkSpecific(e, element.check, element.Route)} className="btn-check" type="checkbox" value="" id={"flexCheckShorts" + element.Route} checked={element.check} />
                                            <label className="btn btn-outline-primary" htmlFor={"flexCheckShorts" + element.Route}>
                                                {element.Route}
                                            </label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div hidden={this.state.Body.length === 0} className='col-12 tableFixHead'>
                        <table className="table align-middle table-hover">
                            <thead className='text-center'>
                                {<tr className='bg-primary text-white'>
                                    {this.state.HeaderTable.Row1.map((col, i) => (
                                        <th className={'text-center ' + ((i + 1) % 2 === 1 ? 'bg-primary' : 'bg-success')} key={i} scope="col" colSpan={col.span}>{col.year}</th>
                                    ))}
                                </tr>}
                                {<tr className='bg-primary text-white'>
                                    <th className='text-center bg-primary ' colSpan={4}></th>
                                    {
                                        this.state.HeaderTable.Row2.map((col, i) => (
                                            <th className={'text-center ' + ((i + 1) % 2 === 1 ? 'bg-dark' : 'bg-secondary')} key={i} colSpan={3}>{col.month}</th>
                                        ))
                                    }
                                </tr>}
                                {<tr className='bg-primary text-white'>
                                    {
                                        this.state.HeaderTable.Row3.map((col, c) => (
                                            col.title.map((tit, t) => (
                                                <th onClick={() => this.OrderPriceMargins(tit, col)} className={'text-center ' + (col.month === '' ? 'bg-primary' : Number(c) % 2 === 1 ? 'bg-dark' : 'bg-secondary')} key={t}><div className='row text-center'><div className='col-12'>{tit}<div className='col-12'>{tit !== '' ? <> <BsFileArrowUpFill /><BsFileArrowDownFill /></> : <></>}</div></div></div></th>
                                            ))
                                        ))
                                    }
                                </tr>}

                            </thead>
                            <tbody>
                                {this.state.BodyFilter.map((element, e) => (
                                    <tr onClick={() => this.DetailofShort(element)} key={uuidv4()} className='text-center'>
                                        <td className='text-center'>{e + 1}</td>
                                        <td className='text-center'>{element.ItemCode}</td>
                                        <td className='text-center'>{element.ProductLine}</td>
                                        <td className='text-center'>{element.ItemCodeDesc}</td>
                                        {element.Detail.map((year) => (
                                            year.Months.map((month) => (
                                                month.shorts === '-' ?
                                                    <React.Fragment key={uuidv4()}>
                                                        <td className='text-center'>0</td>
                                                        <td className='text-end'>$</td>
                                                        <td className='text-end'>0</td>
                                                        {/*<td className='text-center'></td>
                                                        <td className='text-center'>-</td>*/}
                                                    </React.Fragment>

                                                    :
                                                    <React.Fragment key={uuidv4()}>
                                                        <td className='text-center'>{month.shorts}</td>
                                                        <td className='text-end'>{month.dolar}</td>
                                                        <td className='text-end'>{NumberFormat(pricesFormat(month.SalesPrice))}</td>
                                                        {/*<td className='text-end'>{month.dolar}</td>*/}
                                                        {/*<td className='text-end'>{NumberFormat(pricesFormat(month.PurchaseCost))}</td>*/}
                                                    </React.Fragment>
                                            ))
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot className='tfoot'>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>TOTALS</td>
                                    {this.state.Totals.map((total, t) => (
                                        <td className={t === 0 ? 'text-center' : 'text-end'} key={t}>{total}</td>
                                    ))}
                                </tr>

                            </tfoot>
                        </table>
                    </div>
                </div>

                <ModalOrders title={'Shorts of Item: ' + this.state.currentItem.ItemCode} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div>
                        <div className='row'>
                            <div className='col-sm-2'></div>
                            <div className='col-sm-4'>
                                <div className='row'>
                                    <div className='col-12 titleTotal'>
                                        <p>Item Code:</p>
                                    </div>
                                    <div className='col-12 TitleText'>
                                        <p>{this.state.currentItem.ItemCode}</p>
                                    </div>
                                    <div className='col-12 titleTotal'>
                                        <p>Description: </p>
                                    </div>
                                    <div className='col-12 TitleText'>
                                        <p>{this.state.currentItem.ItemCodeDesc}</p>
                                    </div>
                                    <div className='col-12 titleTotal'>
                                        <p>Product Line: </p>
                                    </div>
                                    <div className='col-12 TitleText'>
                                        <p>{this.state.currentItem.ProductLine}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='row'>
                                    <div className='col-12 pt-2 text-center'>
                                        <div className='ItemImg'>
                                            <img key={this.state.currentItem.itemURL} className='pb-5 pt-5' id='ShortsProductImg' src={this.state.currentItem.itemURL !== '' ? this.state.currentItem.itemURL : '/assets/notavailable.png'} alt='ProductImg' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-2'></div>
                            <div className='col-sm-3'></div>
                            <div className='col-sm-6'>
                                <div className="d-grid gap-2">
                                    <ExcelDocument archname={'Shorts Item ' + DeleteCharactersNotAccepted(this.state.currentItem.ItemCode) + ' ' + getDateFromReports() + ' ' + getValueCookie('Company')} sheetname={'Shorts Item ' + DeleteCharactersNotAccepted(this.state.currentItem.ItemCode) + ' ' + getDateFromReports() + ' ' + getValueCookie('Company')} data={getDataSet(this.state.ShortDetailWithTotal, ['Invoice No', 'Customer No', 'Customer Name', 'Price Level', 'Sales Person', 'Route', 'Date', 'Quantity Ordered', 'Quantity Shipped', 'Shorts', 'Unit Cost', 'Unit Price', 'Total Short($)'], ['InvoiceNo', 'CustomerNo', 'BillToName', 'PriceLevel', 'SalespersonNo', 'UDF_RUTA', 'InvoiceDate', 'QuantityOrdered', 'QuantityShipped', 'shorts', 'UnitCostD', 'UnitPriceD', 'TotalShortD'])} />
                                </div>
                            </div>
                            <div className='col-sm-3'></div>
                        </div>
                        <div className='row pt-2'>
                            <div className='col-sm-12 tableFixHead'>
                                <table className='table'>
                                    <thead>
                                        <tr className='bg-dark text-light text-center'>
                                            <th className='bg-dark'>Invoice No</th>
                                            <th className='bg-dark'>Customer No</th>
                                            <th className='bg-dark'>Customer Name</th>
                                            <th className='bg-dark'>Price Level</th>
                                            <th className='bg-dark'>Sales Person</th>
                                            <th className='bg-dark'>Route</th>
                                            <th className='bg-dark'>Date</th>
                                            <th className='bg-dark'>Quantity Ordered</th>
                                            <th className='bg-dark'>Quantity Shipped</th>
                                            <th className='bg-dark'>Shorts</th>
                                            <th className='bg-dark'></th>
                                            <th className='bg-dark'>Unit Cost</th>
                                            <th className='bg-dark'></th>
                                            <th className='bg-dark'>Unit Price</th>
                                            <th className='bg-dark'></th>
                                            <th className='bg-dark'>Total Short($)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.ShortDetail.map((item, i) => (
                                                <tr key={i}>
                                                    <td className='text-center'>{item.InvoiceNo}</td>
                                                    <td className='text-center'>{item.CustomerNo}</td>
                                                    <td className='text-start'>{item.BillToName}</td>
                                                    <td className='text-center'>{item.PriceLevel}</td>
                                                    <td className='text-center'>{item.SalespersonNo}</td>
                                                    <td className='text-start'>{item.UDF_RUTA}</td>
                                                    <td className='text-center'>{DateFormatMAS90(item.InvoiceDate)}</td>
                                                    <td className='text-center'>{NumberFormat(item.QuantityOrdered)}</td>
                                                    <td className='text-center'>{NumberFormat(item.QuantityShipped)}</td>
                                                    <td className='text-center'>{NumberFormat(item.shorts)}</td>
                                                    <td className='text-end'>{'$'}</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(item.UnitCost))}</td>
                                                    <td className='text-end'>{'$'}</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(item.UnitPrice))}</td>
                                                    <td className='text-end'>{'$'}</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(item.TotalShort))}</td>
                                                </tr>
                                            ))
                                        }


                                    </tbody>
                                    <tfoot className='tfoot'>
                                        <tr className='bg-secondary text-light'>
                                            <td className='text-center'></td>
                                            <td className='text-center'></td>
                                            <td className='text-start'></td>
                                            <td className='text-center'></td>
                                            <td className='text-center'></td>
                                            <td className='text-start'></td>
                                            <td className='text-center'>{'TOTAL'}</td>
                                            <td className='text-center'>{NumberFormat(pricesFormat(this.state.currentItem.totalQuantityOrdered))}</td>
                                            <td className='text-center'>{NumberFormat(pricesFormat(this.state.currentItem.totalQuantityShipped))}</td>
                                            <td className='text-center'>{NumberFormat(pricesFormat(this.state.currentItem.totalShort))}</td>
                                            <td className='text-end'></td>
                                            <td className='text-end'></td>
                                            <td className='text-end'></td>
                                            <td className='text-end'></td>
                                            <td className='text-end'>{'$'}</td>
                                            <td className='text-end'>{NumberFormat(pricesFormat(this.state.currentItem.totalShorts))}</td>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                    </div>
                </ModalOrders>


            </div>
        )
    }

}