import React, { Component } from 'react'
import '../css/CartaProducto.css'


export default class CartaProducto extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className="conteiner cartaHover font-family-arial" >
                {this.props.mode === 'Spoilage' ?

                    <div className="row">
                        <div className="col-6 imgC ">
                            <img key={this.props.itemURLImage} src={this.props.itemURLImage ? `${this.props.itemURLImage}` : "./assets/notavailable.png"} alt="Card  cap" className='imgC' />
                        </div>
                        <div className="col-6 descriptionMargin">
                            <div className="row text-start pt-2">
                                <h5 className="card-title"><b>{this.props.ItemCode}</b></h5>
                            </div>
                            <div className="row text-start">
                                <p className="card-text fs-6">{this.props.Description}</p>
                            </div>

                            {
                                this.props.needPrice ?
                                    <div className="row text-start text-success" >
                                        <p className="card-text fs-4"><i>Price Sold:</i> ${this.props.UnitPrice ? this.props.UnitPrice.toFixed(2) : this.props.UnitPrice}</p>
                                    </div>
                                    :
                                    <div></div>
                            }
                            {
                               
                                    <div className="row text-start" >
                                        <p className="card-text fs-5"><b>Quantity Sold:</b> {this.props.OnHand ? this.props.OnHand : 0}</p>
                                    </div>
                                    
                            }


                        </div>
                    </div>
                    :
                    <div className="row item-card pt-2">
                        <div className="col-6 imgC ">
                            <img key={this.props.itemURLImage} src={this.props.itemURLImage ? `${this.props.itemURLImage}` : "./assets/notavailable.png"} alt="Card  cap" className='imgC' />
                        </div>
                        <div className="col-6 descriptionMargin">
                            <div className='row'>
                                <h5>
                                    <span class="badge bg-primary me-3">&nbsp;{this.props.ItemCode}&nbsp;</span>
                                    <span class="badge bg-secondary">OnHand: {this.props.OnHand ? this.props.OnHand : 0}</span>
                                </h5>
                            </div>
                            <div className="text-start">
                                <p className="card-text fs-6">{this.props.Description}</p>
                            </div>

                            {
                                this.props.needPrice ?
                                    <div className="row text-start text-success" >
                                        <p className="card-text fs-4"><i>Unit price:</i> ${this.props.UnitPrice ? this.props.UnitPrice.toFixed(2) : this.props.UnitPrice}</p>
                                    </div>
                                    :
                                    <div></div>
                            }
                            {
                                this.props.needCost ?
                                    <div className="row text-start text-success" >
                                        <p className="card-text fs-4"><i>Unit cost:</i> ${this.props.LastTotalUnitCost ? this.props.LastTotalUnitCost.toFixed(2) : this.props.LastTotalUnitCost}</p>
                                    </div>
                                    :
                                    <div></div>
                            }
                        </div>
                    </div>

                }

            </div>
        )
    }
}
